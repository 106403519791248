import _ from 'lodash'

import template from '../../handlebars/menu/menu.handlebars'

import texts from '../config/texts.js'
import * as pubsub from '../utils/pubsub.js'
import * as ajax from '../utils/ajax-methods.js'
import * as lib from '../utils/lib.js'
import * as stringUtils from '../utils/string.js'

import * as Router from './router.js'

const conf = {
  defaultGroup: texts.groupsSelect.favorites,
  dom: {
    menu: document.getElementById('menu')
  },
  classes: {
    group: 'menu__group',
    groupOpened: 'menu__group--opened',
    groupSelected: 'menu__group--selected',
    groupFavourited: 'menu__group--favourited',
    caption: 'menu__group-caption',
    item: 'menu__item',
    itemSelected: 'menu__item--selected',
    favourite: 'menu__favourite',
    proxyHidden: 'proxy--hidden'
  },
  attributes: {
    groupName: 'data-group-name',
    groupID: 'data-gid',
    hideMenu: 'data-hide-menu',
    noFrame: 'data-no-frame',
    hrefRewrite: 'data-href-rewrite',
    singlePageApp: 'data-single-page-app',
    noSinglePage: 'data-no-single-page'
  }
}

const state = {
  menu: {},
  menuGroups: {},
  favorites: [],
  selectedGroup: '',
  hrefRewrite: false
}

// Main method

const init = ({ menu, groups, favorites = [], uid }) => {
  state.menu = normalize(menu)
  state.groups = groups
  state.favorites = favorites

  recalculateAndRender(Router.getModuleFromUrl(window.location.href).url, true)

  pubsub.subscribe('selectGroupChange', selectGroupChange)
  pubsub.subscribe('urlChange', ({ module }) => { recalculateAndRender(module.url, false) })
  conf.dom.menu.addEventListener('click', (e) => itemClick(e, uid))
}

// Event handlers

const selectGroupChange = ({ groupName }) => {
  state.selectedGroup = groupName
  render()

  const url = Router.getModuleFromUrl(window.location.href).name
  const selectedItem = getLinkByUrl(url)
  computeMenuHeading()
  hideSettings()

  if (!selectedItem) return
  itemsHighlighting(selectedItem)
  remarkSinglePageState(selectedItem)
}

const recalculateAndRender = (url, firstRender) => {
  const strip = src => lib.stripLeadingCharacter(src, '/')

  const selectedModuleUrl = strip(url)
  const allMenuUrls = findMenuUrls(state.menu)
  const bestMatchingUrlInMenu = strip(findBestMatchingUrl(allMenuUrls, selectedModuleUrl))

  if (firstRender) {
    recalculateMenu()
  }

  const groupWithBestMatchingUrl = moduleInCurrentGroup(bestMatchingUrlInMenu, firstRender)
  if (firstRender) {
    state.selectedGroup = groupWithBestMatchingUrl || conf.defaultGroup
    render()
  } else {
    if (!groupWithBestMatchingUrl) return
  }

  const selectedItem = getLinkByUrl(bestMatchingUrlInMenu)
  itemsHighlighting(selectedItem)
  remarkSinglePageState(selectedItem)
  determineIfMenuShouldBeHidden(selectedItem)
  computeMenuHeading()
  hideSettings()
}

const moduleInCurrentGroup = (bestMatchingUrlInMenu, firstRender) => {
  const currentGroup = state.selectedGroup
  if (!firstRender) {
    // check currently opened group
    const group = state.menuGroups[currentGroup]
    const isInGroup = isUrlInsideMenu(group, bestMatchingUrlInMenu)
    if (isInGroup) {
      return currentGroup
    }
  }

  // check favourites
  const favouritesKey = texts.groupsSelect.favorites
  const favourites = state.menuGroups[favouritesKey]
  const isInFavourites = isUrlInsideMenu(favourites, bestMatchingUrlInMenu)
  if (isInFavourites) {
    pubsub.publish('groupChange', { groupName: favouritesKey })
    return favouritesKey
  }

  // check other groups
  const groupsWithoutFavourites = Object.keys(state.menuGroups)
    .filter(x => x !== favouritesKey && (firstRender ? true : x !== currentGroup))

  for (const group of groupsWithoutFavourites) {
    if (isUrlInsideMenu(state.menuGroups[group], bestMatchingUrlInMenu)) {
      pubsub.publish('groupChange', { groupName: group })
      return group
    }
  }

  return false
}

const isUrlInsideMenu = (menu, url) => {
  for (var i = 0; i < menu.length; i++) {
    if (menu[i].url) {
      const menuUrl = lib.stripLeadingCharacter(menu[i].url, '/')
      if (menuUrl === url) return true
    }
    if (menu[i].menu) {
      const urlIsInsideMenu = isUrlInsideMenu(menu[i].menu, url)
      if (urlIsInsideMenu) return true
    }
  }
  return false
}

// Local methods

const itemClick = (e, uid) => {
  // middle mouse click, ctrl+click, or shift+click should only open link in the background
  if (e.which > 1 || e.ctrlKey || e.shiftKey || e.metaKey) return

  const target = e.target

  const clickOnGroup = target.classList.contains(conf.classes.caption)
  const clickOnFavourite = target.classList.contains(conf.classes.favourite)

  if (clickOnGroup) toggleOpenedState(target)
  if (clickOnFavourite) toggleFavorite(target, uid)

  if (target.tagName !== 'A') return
  const url = e.target.getAttribute('href')

  const isModuleUrl = /^\/#\//.test(url)
  if (!isModuleUrl) return
  const moduleName = url.replace(/^\/#\//, '')

  // when modul is single page app (SPA)...
  const singlePageAppParentElm = target.closest(`[${conf.attributes.singlePageApp}]`);
  if (Boolean(singlePageAppParentElm)) {
      if (Boolean(e.target.parentNode.getAttribute(conf.attributes.noSinglePage))) {
        // this page isn't single page (e.g. playground)...
        return;
      }
    const url = e.target.getAttribute('href');
    const groupIDParentElm = e.target.closest(`[${conf.attributes.groupID}]`);
    if (!Boolean(groupIDParentElm)) return;
    const groupID = groupIDParentElm.getAttribute(conf.attributes.groupID);

    // and previous page is in the equal module...
    if (
        new RegExp(`^#/${groupID}/`).test(window.location.hash)
        && singlePageAppParentElm.getAttribute(conf.attributes.singlePageApp) === 'on'
    ) {
        pubsub.publish('menu.clickOnLinkInSinglePage', { url: moduleName })
        const postMessageData = {
            type: 'change-page',
            data: {
                url: '/' + moduleName,
            }
        }

        window.top.postMessage(postMessageData, '*');
        e.preventDefault();
        return;
    }
  }

  // when clicking on link with the same url as we currently have selected
  // browser will not update hash (the urls are the same) so the content
  // in iframe is not reloaded, we fix this manually
  if (url !== '/' + window.location.hash) return

  pubsub.publish('menu.clickOnLinkWithHrefSameAsCurrentUrl', { url: moduleName })
}

const determineIfMenuShouldBeHidden = (target) => {
  if (!target) return

  const noFrameParentEl = target.closest(`[${conf.attributes.noFrame}]`)
  if (noFrameParentEl) {
    // TODO: just use links without /#/ in template
    Router.redirectToModuleWithoutProxy()
    return
  }

  const hrefRewriteParentEl = target.closest(`[${conf.attributes.hrefRewrite}]`)
  state.hrefRewrite = Boolean(hrefRewriteParentEl)

  const hideMenuParentEl = target.closest(`[${conf.attributes.hideMenu}]`)
  if (!hideMenuParentEl) {
    hideProxy(false)
  } else {
    const hideMenuFlagValue = hideMenuParentEl.getAttribute(conf.attributes.hideMenu).toLowerCase()
    if (hideMenuFlagValue === 'true' || hideMenuFlagValue === '1') {
      hideProxy(true)
    } else {
      hideProxy(false)
    }
  }
}

const hideProxy = (hide) => {
  document.body.classList[hide ? 'add' : 'remove'](conf.classes.proxyHidden)
}

const toggleOpenedState = (target) => {
  const groupEl = getClosestGroupEl(target)
  if (!groupEl) return
  groupEl.classList.toggle(conf.classes.groupOpened)
}

const remarkSinglePageState = (target) => {
    // inactive last single page
    document.querySelectorAll(`[${conf.attributes.singlePageApp}=on]`).forEach((item) => item.setAttribute(conf.attributes.singlePageApp, 'off'));
    if (!target) return;

    // is single page app?
    const singlePageAppElm = target.closest(`[${conf.attributes.singlePageApp}]`);
    if (!singlePageAppElm) return;

    // no single page -> no active single page
    const noSinglePageElm = target.closest(`[${conf.attributes.noSinglePage}]`);
    if (noSinglePageElm) return;
    console.log(singlePageAppElm, noSinglePageElm);

    // then active new single page
    singlePageAppElm.setAttribute(conf.attributes.singlePageApp, 'on');
}

const itemsHighlighting = (target) => {
  // remove highlight on previously selected item
  const selectedItemEl = getSelectedItemEl()
  if (selectedItemEl) selectedItemEl.classList.remove(conf.classes.itemSelected)

  // add highlight on newly selected item
  if (!target) return
  target.classList.add(conf.classes.itemSelected)

  // remove previous highlights on groups
  const groupsWithSelectedItem = getGroupsWithSelectedItemEls()
  if (groupsWithSelectedItem.length) [...groupsWithSelectedItem].forEach(item => { item.classList.remove(conf.classes.groupSelected) })

  // highlight all parent headings
  highlightAndOpenParents(target)
}

const highlightAndOpenParents = (el) => {
  let currentGroup = getClosestGroupEl(el)
  while (currentGroup) {
    currentGroup.classList.add(conf.classes.groupSelected)
    currentGroup.classList.add(conf.classes.groupOpened)
    currentGroup = getClosestGroupEl(currentGroup.parentNode)
  }
}

const toggleFavorite = async (target, uid) => {
  const toggleFavourite = (undo = false) => {
    const groupEl = getClosestGroupEl(target)
    const gid = groupEl.getAttribute(conf.attributes.groupID)

    groupEl.classList.toggle(conf.classes.groupFavourited)

    const favourited = !state.favorites.includes(gid)
    state.favorites = favourited
      ? [...state.favorites, gid]
      : state.favorites.filter(x => x !== gid)

    recalculateMenu()

    const favoritesGroupSelected = state.selectedGroup === texts.groupsSelect.favorites
    if (!favoritesGroupSelected) return

    const favoritesEmpty = state.menuGroups[texts.groupsSelect.favorites].length === 0
    if (favoritesEmpty || undo) {
      // rerender whole list when undoing or when favourites are empty so we can
      // display help message for favourites
      render()
    } else {
      // remove only clicked item
      groupEl.remove()
    }
  }

  toggleFavourite(false)

  const response = await ajax.favouriteModule(uid, state.favorites)
  if (response.status !== 200) toggleFavourite(true)
}

const renderMenu = (menu, groupName, target) => {
  const html = template({
    menu: menu[groupName],
    favoritesSelected: groupName === texts.groupsSelect.favorites
  })
  target.innerHTML = html

  conf.dom.menu.innerHTML = html
}

const normalize = (menu) => {
  let normalizedMenu = _.keys(menu)

  normalizedMenu = _.map(normalizedMenu, key => {
    const newMenu = menu[key]
    newMenu.gid = key
    return newMenu
  })

  normalizedMenu = _.filter(normalizedMenu, module => module && module.menu && module.menu.length > 0)

  normalizedMenu = _.map(normalizedMenu, (menu) => {
    if (menu.menu.length === 1 && (!menu.menu[0].menu || menu.menu[0].menu.length === 0)) {
      return {
        ...menu.menu[0],
        title: menu.menu[0].title,
        gid: menu.gid
      }
    }

    return menu
  })

  normalizedMenu = _.map(normalizedMenu, menu => {
    menu.title = menu.title[0].toUpperCase() + menu.title.substring(1)
    return menu
  })

  normalizedMenu = normalizedMenu.sort((a, b) => stringUtils.compareWithDiacritics(a.title, b.title))

  return normalizedMenu
}

const assignModulesToGroups = (groups, menu, favorites) => {
  menu = menu.map(module => {
    module.favourited = favorites.includes(module.gid)
    return module
  })

  const groupsWithMenu = {}

  // group modules by group
  for (const key in groups) {
    groupsWithMenu[key] = groups[key]
      .map(moduleGid => menu.find(x => x.gid === moduleGid))
      .filter(x => x)
  }

  const modulesWithGroup = Object.keys(groups)
    .map(key => groups[key])
    .reduce((a, b) => a.concat(b), [])

  // add modules without group into 'Others' group
  const modulesWithoutGroup = menu.filter(module => !modulesWithGroup.includes(module.gid))
  groupsWithMenu[texts.groupsSelect.others] = modulesWithoutGroup

  // add favourited modules into 'Oblíbené' group
  const favouritedModules = menu.filter(module => favorites.includes(module.gid))
  groupsWithMenu[texts.groupsSelect.favorites] = favouritedModules

  // add all modules into All modules group
  groupsWithMenu[texts.groupsSelect.all] = menu

  return groupsWithMenu
}

const computeMenuHeading = () => {
  const headings = []

  const selectedItem = getSelectedItemEl()
  if (!selectedItem) return

  headings.push(selectedItem.innerHTML)

  let group = getClosestGroupEl(selectedItem)
  while (group) {
    const headingEl = group.querySelector(`.${conf.classes.caption}`)
    if (!headingEl) break

    const heading = headingEl.textContent || headingEl.innerText
    headings.push(heading)

    group = getClosestGroupEl(group.parentNode)
  }

  headings.push(state.selectedGroup.toUpperCase())

  let text = headings
    .map(x => x.trim())
    .reverse()
    .join(' / ')

  pubsub.publish('menuRendered', { text })
}

const render = () => { renderMenu(state.menuGroups, state.selectedGroup, conf.dom.menu) }

const recalculateMenu = () => { state.menuGroups = assignModulesToGroups(state.groups, state.menu, state.favorites) }

const getSelectedItemEl = () => document.querySelector(`.${conf.classes.itemSelected}`)

const getClosestGroupEl = (el) => el.closest(`.${conf.classes.group}`)

const getGroupsWithSelectedItemEls = () => document.querySelectorAll(`.${conf.classes.groupSelected}`)

const getLinkByUrl = (url) => conf.dom.menu.querySelector(`[href^="/#/${url}"]`)

/**
 * Takes two arrays and counts how many matching items on the same position
 * there are. Eg.: [freshmon, news, monitoring] in array1 and [freshmon, news]
 * in array2 will return 2.
 * @param  {array} array1
 * @param  {array} array2
 * @return {int}
 */
const countMatchingItems = (array1, array2) => {
  let count = 0
  for (let i = 0; i < array1.length; i++) {
    if (array1[i] === array2[i]) {
      count++
    } else {
      // TODO consider putting break here because we do not want the count to raise
      // for items which match only for nested items, eg /a/item/1 and /b/item/1
      // have count 2 but it is completly different module
    }
  }
  return count
}

const findMenuUrls = (menu, urls = []) => {
  for (var i = 0; i < menu.length; i++) {
    if (menu[i].url) urls.push(menu[i].url)
    if (menu[i].menu) urls = [...urls, ...findMenuUrls(menu[i].menu)]
  }
  return urls
}

const findBestMatchingUrl = (allUrls, moduleUrl) => {
  let bestUrl = false
  let bestMatch = 0

  for (const url of allUrls) {
    const split = /[/?#]/
    const match = countMatchingItems(
      url.split(split).filter(x => x),
      moduleUrl.split(split).filter(x => x)
    )
    if (match > 0 && match > bestMatch) {
      bestMatch = match
      bestUrl = url
    }
  }

  return bestUrl
}

const hideSettings = () => {
  // TODO: only temporary solution until we have proper router solution
  const el = conf.dom.menu.querySelector('[href="/#/proxy/my-settings.html"]')
  if (!el) return

  let currentGroup = getClosestGroupEl(el)
  let settingsWrapper

  while (currentGroup) {
    settingsWrapper = currentGroup
    currentGroup = getClosestGroupEl(currentGroup.parentNode)
  }

  settingsWrapper.style.display = 'none'
}

const isLinksFixingEnabled = () => {
  return state.hrefRewrite
}

// Export

export { init, isLinksFixingEnabled }
